<template>
    <div>
        <transition-group
            name="staggered-fade"
            tag="div"
            v-bind:css="false"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:leave="leave"
            v-if="showContent"
        >
            <slot name="content"></slot>
        </transition-group>

        <slot name="empty" v-if="showEmpty"></slot>
        <div class="card-body text-center" v-if="showLoader">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import Velocity from "velocity-animate";
export default {
    name: "zs-list-transition",
    props: {
        showLoader: {
            default: true,
        },
    },
    computed: {
        showContent() {
            return !!this.$slots.content && !this.showLoader;
        },
        showEmpty() {
            return !this.$slots.content && !this.showLoader;
        },
    },
    methods: {
        beforeEnter: function (el) {
            el.style.opacity = 0;
        },
        enter: function (el, done) {
            var delay = el.dataset.index * 50;
            setTimeout(function () {
                Velocity(el, { opacity: 1 }, { complete: done });
            }, delay);
        },
        leave: function (el, done) {
            var delay = el.dataset.index * 50;
            setTimeout(function () {
                Velocity(el, { opacity: 0 }, { complete: done });
            }, delay);
        },
    },
};
</script>
